

import React, { Component } from 'react';
import Routes from './routes'
import socketIO from 'socket.io-client';
import popup_bg from './assets/images/popupbg.png';
import coupon from './assets/images/coupon.png';
import logo from './assets/svg/logo.svg';
import logoDark from './assets/svg/logo-dark.svg'
import DocumentMeta from 'react-document-meta';
import {
  Container,
  Row,
  Col,
  NavItem,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Button,

} from 'reactstrap';
import Isvg from 'react-inlinesvg';
import cart_icon from './assets/svg/cart-icon.svg'
import langs from './langs.json';


Number.prototype.formatPrice = function (currency) {
  let price = this;
  price = price * currency.exchangeRate;
  let dec_point = ',';
  let thousands_sep = '.';

  var parts = price.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point) + ' ' + currency.code;
}

String.prototype.formatPrice = function (currency) {
  let price = parseFloat(this);
  price = price * currency.exchangeRate;
  let dec_point = ',';
  let thousands_sep = '.';

  var parts = price.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point) + ' ' + currency.code;
}


Object.translate = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o[lang] ? o[lang] : o['ba'];
}

class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.translate = this.translate.bind(this);
    this.updateBreadcrumb = this.updateBreadcrumb.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.allowCookies = this.allowCookies.bind(this);
    this.showInfoMessage = this.showInfoMessage.bind(this);
    this.hideInfoMessage = this.hideInfoMessage.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    window.googleMapsCallback = this.googleMapsCallback;
    this.setCurrency = this.setCurrency.bind(this);


    this.state = {
      instagramPhotos: [],
      lang: 'ba',
      breadcrumb: [],
      categories: [],
      uData: null,
      cartCount: 0,
      newestProducts: [],
      popularProducts: [],
      offerProducts: [],
      topProducts: [],
      brands: {},
      news: [],
      banners: [],
      brands: [],
      brandsCat: [],

      infoMessages: {

      },
      currency: {
        code: 'KM',
        exchangeRate: 1
      },
      pages: {
        'o-nama': {},
        'kako-naruciti': {},
        'privacy-policy': {},
        'terms-and-conditions': {},
        'narucivanje-i-dostava': {},
        'placanje': {},
        'kupovina': {},
        'dostava': {},
        'politika-privatnosti': {},
        'pitanja-i-odgovori': {},
        'uslovi-kupovine': {},
        'povrat-proizvoda-i-reklamacije': {}
      }
    }
  }


  changeLanguage = (lang) => {
    this.setState({
      lang: lang
    })
  }


  handleArticleModal = (product) => {
    product.SelectedAttributes = {};

    this.setState({
      articleModal: product
    })


    this.state.socketIOClient.emit('fetchProductAttributes', {
      _id: product._id,
      attributes: product.Attributes
    }) 
    
    this.state.socketIOClient.once('fetchProductAttributes',
    (data) => {

      if (data._id == product._id) {
        let article = product;
        article.attributes = data.attributes;
        this.setState({
          articleModal: article
        })
      }
    })
  }
  addToCart = (product) => {

    if (product.Attributes && product.Attributes.length){
      this.handleArticleModal({
          _id: product._id,
          Alias: product.Alias,
          Name: product.Name,
          Attributes: product.Attributes,
          Image: product.Image,
          price: product.price,
          quantity: product.quantity
      });

      return;
  }


    if (parseInt(this.state.quantity) < product.MinOrder) {
      this.props.showInfoMessage('Za ovaj proizvod postoji minimalna količina.', true);
      return;
    }



    this.state.socketIOClient.emit('addToCart', { _id: product._id, quantity: parseInt(product.quantity) });
    this.state.socketIOClient.once('addToCart', (data) => {
      if (data.successful) {
        this.showInfoMessage('Proizvod je uspješno dodat u korpu!');
        this.state.socketIOClient.emit('cartInfo', {});
        this.setState({
          articleModal: null
        })
      } else {
        this.showInfoMessage('Proizvod trenutno nije na stanju', true);
        this.setState({
          articleModal: null
        })

      }
    })

    return false;
  }


  addToCart2 = () => {

    if (parseInt(this.state.quantity) < this.state.articleModal.MinOrder) {
      this.props.showInfoMessage('Za ovaj proizvod postoji minimalna količina.', true);
      return;
    }


    let selectedAttributes = this.state.articleModal.SelectedAttributes;
    let attributes = this.state.articleModal.attributes;

    for (let i = 0; i < attributes.length; i++) {
      if (!selectedAttributes[attributes[i].Alias]) {
        this.showInfoMessage('Selektujte sve atribute', true);
        return;
      }
    }

    this.state.socketIOClient.emit('addToCart', { _id: this.state.articleModal._id, quantity: parseInt(this.state.articleModal.quantity), selectedAttributes: selectedAttributes });
    this.state.socketIOClient.once('addToCart', (data) => {
      if (data.successful) {
        this.showInfoMessage('Proizvod je uspješno dodat u korpu!');
        this.state.socketIOClient.emit('cartInfo', {});
        this.setState({
          articleModal: null
        })
      } else {
        this.showInfoMessage('Proizvod trenutno nije na stanju', true);
        this.setState({
          articleModal: null
        })

      }
    })

    return false;
  }

  setCurrency(currency) {
    this.setState({
      currency
    })
  }

  showInfoMessage(text, error) {
    console.log(this.state.infoMessages);
    let messages = this.state.infoMessages;
    let idx = Date.now().toString();
    messages[idx] = {
      idx: idx,
      message: text,
      error: error
    };

    this.setState({
      infoMessages: messages
    }, () => {
      this.forceUpdate();
      setTimeout(() => {
        this.hideInfoMessage(idx);
      }, 3000);
    });



  }

  hideInfoMessage(idx) {
    let messages = this.state.infoMessages;
    if (!messages[idx])
      return;
    messages[idx].animate = true;
    this.setState({
      infoMessages: messages
    }, () => {
      setTimeout(() => {


        let messages = this.state.infoMessages;
        delete messages[idx];
        this.setState({
          infoMessages: messages
        })
      }, 1000);
    })
  }

  hidePopup() {
    localStorage.popup = Math.floor(Date.now() / 1000);
    console.log(true)
    this.setState({
      popup: null
    })
  }

  allowCookies() {
    localStorage.allowCookies = true;
    this.setState({
      cookies: true
    });
  }


  updateBreadcrumb(bcrumb) {
    this.setState({
      breadcrumb: bcrumb
    });
  }

  googleMapsCallback() {
    this.setState({
      _googleMapsLoaded: true
    });
  }

  setUserData(data) {
    this.setState({
      uData: data
    });

    localStorage.uData = JSON.stringify(data);

  }

  componentDidMount() {

    /*
    fetch('https://www.instagram.com/msistembn/', {
      headers: { 'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.80 Safari/537.36' }
    }).then((res) => res.text()).then((source) => {
      try {
        var json = JSON.parse(source.split('window._sharedData =')[1].split(';</script>')[0]);
        let edges = json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges;
        console.log(edges)
        let instagramPhotos = edges.map((item) => {
          return {
            preview: item.node.thumbnail_resources[0].src,
            link: 'https://instagram.com/p/' + item.node.shortcode
          }
        });
        this.setState({
          instagramPhotos: instagramPhotos.slice(0, 6)
        })
      } catch (e) {

      }



    })
    */

    if (localStorage.popup) {
      if (parseInt(localStorage.popup) + 24 * 60 * 60 < Math.floor(Date.now() / 1000)) {
        //alert(true);
        this.setState({
          popup: true
        })
      }
    } else {
      this.setState({
        popup: true
      })
    }

    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }

    let socket = socketIO('https://socket.mlmcompany.ba');

    console.log(socket);
    this.setState({
      socketIOClient: socket
    });


    if (localStorage.uData) {
      let uData = JSON.parse(localStorage.uData);

      if (uData && uData.User && uData.User.EMail && uData.User.pk)
        socket.emit("userVerify", { email: uData.User.EMail, pk: uData.User.pk });
    }


    socket.on('cartInfo', (data) => {
      this.setState({
        cartCount: data.count
      })
    })

    socket.on('fetchBrands', (data) => {
      this.setState({
        brands: data
      })
    })

    socket.on('fetchBrandsCat', (data) => {
      this.setState({
        brandsCat: data
      })
    })

    socket.on('fetchPdf', (data) => {
      this.setState({
        pdf: data
      })
    })

    socket.on('userVerify', (data) => {
      console.log(data);
      if (data.successful) {
        this.setUserData(data.user);
      }

      socket.emit('cartInfo', {});

    });

    socket.on('fetchBanners', (data) => {
      console.log(data);
      this.setState({
        banners: data
      })
    });

    socket.on('fetchCategories', (data) => {
      console.log(data);
      this.setState({
        categories: data
      });
    });

    socket.on('fetchNews', (data) => {
      console.log(data);
      this.setState({
        news: data
      });
    });



    socket.on('fetchPopup', (data) => {
      console.log(data);
      this.setState({
        popupData: data
      });
    });
    socket.on('fetchPages', (data) => {
      console.log(data);
      this.setState({
        pages: data
      });
    });



    socket.on('userLogout', (data) => {
      socket.emit('cartInfo', {});
    })
    socket.emit('fetchBanners', {});

    socket.emit('fetchCategories', {});

    socket.emit('fetchBrands', {});

    socket.emit('fetchBrandsCat', {});



    socket.emit('fetchPdf', {});

    socket.emit('fetchNews', {});
    socket.emit('fetchPages', {});

    socket.on('siteData', (data) => {
      console.log(data);
      this.setState({
        siteData: data
      });
    });

    /*
    fetch('https://www.instagram.com/msistembn/', {
      headers: { 'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.80 Safari/537.36' }
    }).then((res) => res.text()).then((source) => {
      try {
        var json = JSON.parse(source.split('window._sharedData =')[1].split(';</script>')[0]);
        let edges = json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges;
        console.log(edges)
        let instagramPhotos = edges.map((item) => {
          return {
            preview: item.node.thumbnail_resources[0].src,
            link: 'https://instagram.com/p/' + item.node.shortcode
          }
        });
        this.setState({
          instagramPhotos: instagramPhotos.slice(0, 6)
        })
      } catch (e) {

      }



    })
    */


  }


  translate(text) {
    if (langs[this.state.lang][text]) {
      return langs[this.state.lang][text];
    } else {
      return text
    }
  }

  render() {

    console.log(this.state)

    const meta = {
      title: 'MLM Company',
      meta: {
        charset: 'utf-8',
        name: {
          'og:title': 'MLM Company',
          'og:image': 'https://mlmcompany.ba/social.png'
        }
      }
    };


    return (
      <div>
        <DocumentMeta {...meta}>
        </DocumentMeta>

        <Routes
          translate={this.translate}
          updateBreadcrumb={this.updateBreadcrumb}
          setUserData={this.setUserData}
          allowCookies={this.allowCookies}
          showInfoMessage={this.showInfoMessage}
          hideInfoMessage={this.hideInfoMessage}
          hidePopup={this.hidePopup}
          setCurrency={this.setCurrency}
          addToCart={this.addToCart}
          handleArticleModal={this.handleArticleModal}
          changeLanguage={this.changeLanguage}

          {...this.state}
        />
        <div className="pop-up-messages">
          {
            Object.values(this.state.infoMessages).map((item, idx) => {
              console.log(item);
              return (
                <div className={item.animate ? 'hide-message ' : ''} key={idx} onClick={() => this.hideInfoMessage(item.idx)}>
                  <i className="mdi mdi-close hide" />

                  <p className={item.error ? 'error' : ''}>{item.error ? <i className="mdi mdi-close" ></i> : null}{item.message}</p>
                </div>
              )
            })
          }

        </div>

        {this.state.popup && this.state.popupData ?
          <div className="popup">
            <img src={this.state.popupData.Background} className="overlay" />
            <div className="content">
              <img src={this.state.popupData.Image} className="coupon" />
              <h2>{this.state.popupData.Title}</h2>
              <h3>{this.state.popupData.Subtitle}</h3>
              <h6>{this.state.popupData.Text}</h6>
              <button className="button" onClick={() => this.hidePopup()}>{this.translate('BEGINN MIT DEM EINKAUF')}</button>
            </div>
          </div>
          : null
        }

{
            this.state.articleModal ?

              <div className="article-modal">

                <div className="content" ref={(node) => this.wrapperRef = node}>
                  <Col xl="5" lg="5" md="5" sm="12" xs="12">
                    <img src={this.state.articleModal.Image} className="img-fluid artikal"></img>
                  </Col>
                  <Col xl="7" lg="7" md="7" sm="12" xs="12">
                    <h1>{this.state.articleModal.Name && this.state.articleModal.Name[this.state.lang]}</h1>
                    <hr />
                    <p className="price">{this.state.articleModal.price ?  this.state.articleModal.price.formatPrice(this.state.currency): null}</p>

                    <Row>
                      {
                        this.state.articleModal.attributes ?
                          this.state.articleModal.attributes.map((item, idx) => {
                            return (
                              <Col xs="6">

                                <div className="input-wrap">
                                  <label>{item.Name && item.Name[this.state.lang]}</label>
                                  <select value={this.state.articleModal.SelectedAttributes[item.Alias]} onChange={(e) => {
                                    let article = this.state.articleModal;
                                    article.SelectedAttributes[item.Alias] = e.target.value;
                                    this.setState({
                                      articleModal: article
                                    })
                                  }} placeholder="Izaberite" className="form-control">
                                    <option disabled selected>Izaberite...</option>
                                    {
                                      item.Values && item.Values[this.state.lang] && item.Values[this.state.lang].map((value) => {
                                        return (
                                          <option value={value}>{value}</option>

                                        )
                                      })
                                    }
                                  </select>
                                </div>
                              </Col>

                            )
                          })

                          : null
                      }

                    </Row>


                    <div className="spacer">
                      <Button className="btn1" onClick={this.addToCart2}><Isvg src={cart_icon}></Isvg>U KORPU</Button>
                      <Button className="btn2" onClick={() => {
                        this.setState({
                          articleModal: null
                        })
                      }}>ZATVORI</Button>

                    </div>

                  </Col>

                </div>


              </div>

              : null
          }

        <div className="loader">
          <img src={logoDark} />
        </div>

      </div>

    );

  }

}

export default App;
